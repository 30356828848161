import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  RoleByIDService,
  RoleUpdateService,
  PermissionListService,
  RolePermissionsService,
  updateRolePermissions,
} from "../services/EditRoleService";
import { EditRole } from "../modules/EditRole";
import Spinner from "components/MDSpinner";
import MDAlert from "components/Alert";

export default function RoleEditController() {
  const { id } = useParams(); // Obtener el ID del rol desde la URL
  const navigate = useNavigate(); // Para redirigir después de guardar
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState({});
  const [permissions, setPermissions] = useState([]);
  const [permissionsByRol, setPermissionsByRol] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [message, setMessage] = useState("");
  const [isAlert, setIsAlert] = useState(false);
  const [isFormModified, setIsFormModified] = useState(false);

  const fetchRoleAndPermissions = async () => {
    try {
      const roleResult = await RoleByIDService(id);
      if (roleResult) {
        setRole(roleResult.payload);
        setSelectedPermissions(roleResult.payload.permissions || []);
      }

      // Llama al servicio RolePermissionsService
      const rolePermissionsResult = await RolePermissionsService(id);
      if (rolePermissionsResult.success) {
        setPermissionsByRol(rolePermissionsResult.payload);
      }

      // Si también necesitas cargar todos los permisos basados en software_id
      const software_id = localStorage.getItem("software_id");
      const permissionsResult = await PermissionListService(software_id);
      if (permissionsResult.success) {
        setPermissions(permissionsResult.payload);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch data when the component is mounted
  useEffect(() => {
    fetchRoleAndPermissions();
  }, [id]);

  // Update the field value when user changes it
  const handleFieldChange = (field, value) => {
    setRole({ ...role, [field]: value });
    console.log(role);
    setIsFormModified(true);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // Excluir software_id del objeto role
      const { software_id, ...updatedRole } = role;
      console.log(role);
      const result = await RoleUpdateService(id, updatedRole);
      if (result.success) {
        handleAlert("success", "El rol se ha actualizado correctamente");
        navigate("/roles"); // Redirige a la lista de roles después de guardar
      } else {
        handleAlert("fail", result.message);
      }
    } catch (error) {
      handleAlert("fail", error.message || "Error al actualizar el rol");
    } finally {
      setLoading(false);
    }
  };

  // Handle updating permissions
  const handleUpdatePermissions = async (permissions) => {
    setLoading(true);
    try {
      const result = await updateRolePermissions(id, permissions);
      if (result.success) {
        await fetchRoleAndPermissions(); // Esperar a que se recarguen los permisos
        handleAlert("success", "Permisos actualizados correctamente");
      } else {
        handleAlert("fail", result.message);
      }
    } catch (error) {
      handleAlert("fail", error.message || "Error al actualizar los permisos");
    } finally {
      setLoading(false);
    }
  };

  // Handle alerts
  const handleAlert = (typeAlert, message) => {
    setMessage({ type: typeAlert, text: message });
    setIsAlert(true);
  };

  if (loading) return <Spinner loading={loading} />;

  return (
    <>
      <MDAlert
        isAlert={isAlert}
        setIsAlert={setIsAlert}
        typeAlert={message.type}
        message={message.text}
      />
      <EditRole
        role={role}
        handleRolePermitList={permissions.payload}
        handlePermiByRolList={permissionsByRol.payload}
        handleFieldChange={handleFieldChange}
        isFormModified={isFormModified}
        handleSubmit={handleSubmit}
        handleUpdatePermissions={handleUpdatePermissions}
      />
    </>
  );
}

/* 
██╗██╗░░░██╗  ██████╗░██╗░██████╗░██╗████████╗░█████╗░██╗░░░░░
██║██║░░░██║  ██╔══██╗██║██╔════╝░██║╚══██╔══╝██╔══██╗██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░██╗░██║░░░██║░░░███████║██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░╚██╗██║░░░██║░░░██╔══██║██║░░░░░
██║╚██████╔╝  ██████╔╝██║╚██████╔╝██║░░░██║░░░██║░░██║███████╗
╚═╝░╚═════╝░  ╚═════╝░╚═╝░╚═════╝░╚═╝░░░╚═╝░░░╚═╝░░╚═╝╚══════╝
╔════════════════════════════════════════════════════════════╗
║              EQUIPO DE DESARROLLO DE SOFTWARE              ║
║                   MODULO DESARROLLADO POR                  ║
╟┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄╢
║ Victor Manuel Calle Londoño                                ║
║ victor.calle@est.iudigital.edu.co                          ║
╚════════════════════════════════════════════════════════════╝
*/
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Icon,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Typography,
  TextField,
  styled,
  Button,
  Card,
  LinearProgress,
  CircularProgress,
  InputLabel,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  FormHelperText,
} from "@mui/material";
import colors from "assets/theme/base/colors";
// import closeImg from "assets/images/close_X.svg";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import PropTypes from "prop-types";
import MDAlert from "components/MDAlert";
import React, { useState } from "react";
import DataTable from "examples/Tables/DataTable";
import MDProgress from "components/MDProgress";
import Spinner from "components/MDSpinner";

export function ParticipantUpload({
  open,
  handleClose,
  isFormModified,
  isDisabled,
  isAlert,
  errorAlert,
  spinner,
  getFieldValue,
  sourceTypes,
  carnetTypes,
  handleFieldChange,
  handleSubmit,
  handleEstablishConnection,
  setColumTableNative,
  columTableNative,
  columData,
  statusConexion,
  tableRows,
  loading,
  isFieldValid,
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: "1100px",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
    maxHeight: "95vh",
  };

  const modalContentStyle = {
    padding: "20px",
    maxHeight: "80%", // Establece la altura máxima para el contenido del modal
    overflowY: "auto", // Agregar scroll vertical al contenido del modal
  };

  const content = {
    margin: "0",
    padding: "0",
    display: "grid",
    align: "center",
    width: "100%",
    gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
    gap: "15px",
  };
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const tableColumns = [
    { Header: "BD Nativa", accessor: "staticValue" },
    { Header: "BD Entrante", accessor: "select" },
    // Tus otras columnas aquí...
  ];

  let activeButtomSubmit = true;
  // const activeButtomSubmit = isFormModified === true && isFieldValid.EXCEL === true ? false : true;
  if (isFormModified === true) {
    activeButtomSubmit = true;
  } else {
    activeButtomSubmit = isFormModified === false && isFieldValid.EXCEL === false ? true : false;
  }

  return (
    <>
      {/* <Spinner loading={loading}></Spinner> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={style}>
          <MDBox
            mt={-7}
            color="white"
            bgColor="info"
            variant="gradient"
            borderRadius="lg"
            p={2}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Typography id="modal-modal-title" component="h2">
              Nueva fuente de datos
            </Typography>
            <Icon
              sx={{
                fontSize: "2em",
                stroke: "currentColor",
                strokeWidth: "2px",
                cursor: "pointer",
                marginLeft: "auto",
              }}
              onClick={handleClose}
            >
              close
            </Icon>
          </MDBox>
          <Divider variant="middle" />
          <div style={{ maxHeight: "80%", overflowY: "auto" }}>
            <form onSubmit={handleSubmit}>
              <MDBox
                sx={content}
                style={{
                  justifyContent: "space-around",
                }}
              >
                <Box>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Tipo de fuente
                  </Typography>
                  <FormControl sx={{ minWidth: 80 }}>
                    <Select
                      labelId="labelId-select-tipo-de-fuente"
                      id="select-tipo-de-fuente"
                      value={getFieldValue("name")}
                      onChange={(e) => handleFieldChange("name", e.target.value)}
                      style={{ fontSize: "15px", padding: "10px" }}
                      displayEmpty
                    >
                      <MenuItem value={""}>Seleccione...</MenuItem>
                      <MenuItem key={"EXCEL"} value={"EXCEL"}>
                        {"EXCEL"}
                      </MenuItem>
                    </Select>
                    {!isFieldValid.name && (
                      <FormHelperText className="Mui-error">
                        Tipo de fuente requerida
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>

                {getFieldValue("name") === "EXCEL" && (
                  <Box sx={{ marginRight: "20px" }}>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      Fuente: Archivo excel
                    </Typography>
                    <>
                      <span>
                        <a
                          href="/assets/Plantilla Excel.xlsx"
                          download="Plantilla Excel.xlsx"
                          target="_self"
                          title="Descarga la plantilla de ejemplo"
                          style={{ fontSize: "1.1rem" }}
                        >
                          Descargar plantilla
                        </a>
                      </span>
                      <MDInput
                        style={{ marginTop: "5px" }}
                        label=""
                        type="file"
                        error={!isFieldValid.EXCEL}
                        helperText={
                          !isFieldValid.EXCEL ? "El archivo no es de extension excel" : ""
                        }
                        FormHelperTextProps={{
                          className: !isFieldValid.EXCEL ? "Mui-error" : "",
                        }}
                        onChange={(e) => {
                          const selectedFile = e.target.files[0];
                          handleFieldChange("source", selectedFile);
                        }}
                      ></MDInput>
                      {!getFieldValue("source") && (
                        <FormHelperText className="Mui-error">Fuente requerida</FormHelperText>
                      )}
                      <label
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "100px",
                          display: "inline-block",
                        }}
                        title={getFieldValue("source") ? getFieldValue("source").name : ""}
                      >
                        {getFieldValue("source") ? getFieldValue("source").name : ""}
                      </label>
                    </>
                  </Box>
                )}
              </MDBox>
            </form>
          </div>
          <Divider variant="middle" />

          <MDBox
            style={{
              display: "flex",
              justifyContent: "space-around",
            }}
            m={-0.5}
            mb={-2}
          >
            <MDButton color="error" onClick={handleClose}>
              Cancelar
            </MDButton>
            <MDButton
              onClick={handleSubmit}
              disabled={activeButtomSubmit}
              type="submit"
              color="success"
            >
              Guardar
            </MDButton>
          </MDBox>
        </Card>
      </Modal>
    </>
  );
}

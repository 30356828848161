import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

export default function ParticipantList({
  handleParticipantCreate,
  handleParticipantList,
  handleopenNewUpload,
}) {
  // Definir las columnas para la tabla
  const columns = [
    { Header: "Documento", accessor: "document", align: "center" },
    { Header: "Nombre", accessor: "name", align: "center" },
    { Header: "Apellido", accessor: "last_name", align: "center" },
    { Header: "Estado", accessor: "state", align: "center" },
    { Header: "Género", accessor: "gender", align: "center" },
    { Header: "Ubicación Geográfica", accessor: "location", align: "center" },
    { Header: "Programa Académico", accessor: "academic_program", align: "center" },
    { Header: "Semestre", accessor: "semester", align: "center" },
    { Header: "acciones", accessor: "action", width: "12%", align: "center" },
  ];

  // Construir las filas basadas en la lista de participantes
  const rows = handleParticipantList
    ? handleParticipantList.map((participant) => ({
        document: <MDTypography variant="caption">{participant.document}</MDTypography>,
        name: <MDTypography variant="caption">{participant.name}</MDTypography>,
        last_name: <MDTypography variant="caption">{participant.last_name}</MDTypography>,
        state: <MDTypography variant="caption">{participant.state}</MDTypography>,
        gender: <MDTypography variant="caption">{participant.gender}</MDTypography>,
        location: <MDTypography variant="caption">{participant.location}</MDTypography>,
        academic_program: (
          <MDTypography variant="caption">{participant.academic_program}</MDTypography>
        ),
        semester: <MDTypography variant="caption">{participant.semester}</MDTypography>,
        created: (
          <MDTypography variant="caption">
            {new Date(participant.created).toLocaleString()}
          </MDTypography>
        ),
        action:
          ((
            <MDBox>
              <MDButton variant="text" color="error">
                <DeleteForeverOutlinedIcon />
              </MDButton>
            </MDBox>
          ),
          (
            <EditOutlinedIcon
              titleAccess="Editar"
              style={{
                fontSize: "200px",
                width: "24px",
                height: "24px",
                backgroundImage: "#000000",
              }}
            />
          )),
      }))
    : [];

  return (
    <>
      <MDBox
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginRight: "2vw",
          marginTop: "10px",
          marginBottom: "20px",
        }}
      >
        <MDButton
          id="crear_individual"
          onClick={handleParticipantCreate}
          variant="gradient"
          color="info"
          sx={{ marginRight: "10px" }}
        >
          Crear
        </MDButton>
        <MDButton id="crear_masivo" onClick={handleopenNewUpload} variant="gradient" color="info">
          Carga masiva
        </MDButton>
      </MDBox>

      {/* Mostrar la tabla con DataTable */}
      <DataTable
        table={{ columns, rows }}
        isSorted={true} // Habilitar ordenamiento
        entriesPerPage={true} // Mostrar control de entradas por página
        showTotalEntries={true} // Mostrar el total de entradas
      />
    </>
  );
}

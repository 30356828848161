import React from "react";
import {
  Box,
  Modal,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Divider,
  Select,
  Card,
  FormHelperText,
} from "@mui/material";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

export function ActivitiesCreate({
  open,
  handleClose,
  isDisabled,
  getFieldValue,
  handleFieldChange,
  handleSubmit,
  isFieldValid,
  fetchSubCategories,
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: "400px",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card sx={style}>
        <MDBox
          mt={-7}
          color="white"
          bgColor="info"
          variant="gradient"
          borderRadius="lg"
          p={2}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Typography id="modal-modal-title" component="h2">
            Crear Actividad
          </Typography>
          <Button
            sx={{
              fontSize: "2em",
              stroke: "currentColor",
              strokeWidth: "2px",
              cursor: "pointer",
              marginLeft: "auto",
            }}
            onClick={handleClose}
          ></Button>
        </MDBox>
        <Divider variant="middle" />
        <form onSubmit={handleSubmit}>
          <Box mb={2}>
            <TextField
              fullWidth
              label="Fecha"
              type="date"
              InputLabelProps={{ shrink: true }}
              name="fecha"
              value={getFieldValue("fecha")}
              onChange={(e) => handleFieldChange("fecha", e.target.value)}
              required
              disabled={isDisabled}
            />
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              label="Título"
              name="title"
              value={getFieldValue("title")}
              onChange={(e) => handleFieldChange("title", e.target.value)}
              required
              disabled={isDisabled}
            />
          </Box>
          <Box sx={{ marginRight: "20px" }}>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Subcategorías
            </Typography>
            <FormControl sx={{ minWidth: 80 }} error={isFieldValid?.categories === false}>
              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                value={getFieldValue("subcategories")}
                onChange={(e) => handleFieldChange("subcategories", e.target.value)}
                style={{ fontSize: "15px", padding: "10px" }}
                disabled={isDisabled}
                displayEmpty
              >
                <MenuItem value="">Seleccione...</MenuItem>
                {Array.isArray(fetchSubCategories) &&
                  fetchSubCategories.map((category) => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.name}
                    </MenuItem>
                  ))}
              </Select>
              {isFieldValid?.categories === false && (
                <FormHelperText>Categoría requerida</FormHelperText>
              )}
            </FormControl>
          </Box>
          <br />
          <Box mb={2}>
            <TextField
              fullWidth
              label="Descripción"
              name="description"
              value={getFieldValue("description")}
              onChange={(e) => handleFieldChange("description", e.target.value)}
              required
              disabled={isDisabled}
            />
          </Box>
          <Box mb={2}>
            <FormControl fullWidth>
              <InputLabel id="is-certifiable-label">Certificable</InputLabel>
              <Select
                labelId="is-certifiable-label"
                id="is-certifiable"
                name="is_certifiable"
                value={getFieldValue("is_certifiable") == true ? "true" : "false"}
                onChange={(e) => handleFieldChange("is_certifiable", e.target.value)}
                disabled={isDisabled}
                required
              >
                <MenuItem value={true}>Sí</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Divider variant="middle" />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "5px",
            }}
          >
            <MDButton type="submit" color="success" disabled={isDisabled}>
              Guardar
            </MDButton>
            <MDButton onClick={handleClose} color="error" disabled={isDisabled}>
              Cancelar
            </MDButton>
          </Box>
        </form>
      </Card>
    </Modal>
  );
}

ActivitiesCreate.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  isDisabled: PropTypes.bool,
  getFieldValue: PropTypes.func,
  handleFieldChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  isFieldValid: PropTypes.shape({
    categories: PropTypes.bool,
    title: PropTypes.bool,
    description: PropTypes.bool,
    is_certifiable: PropTypes.bool,
  }),
  fetchSubCategories: PropTypes.array,
};

export const createEvidence = async (payload) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/evidence/create/`, {
      method: "POST",
      body: payload,
    });
    if (response.ok) {
      const result = await response.json();

      return result;
    } else {
      throw new Error(`Error del servidor al realizar la solicitud`);
    }
  } catch (error) {
    const message = "Error del servidor al realizar la solicitud";
    const status = false;
    return { success: false, message: error.message || message };
  }
};

export async function EvidenceListService(id, pageIndex) {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/evidence/${id}/`);
    if (response.ok) {
      const result = await response.json();

      return result;
    } else {
      throw new Error(`Error del servidor al realizar la solicitud`);
    }
  } catch (error) {
    const message = "Error del servidor al realizar la solicitud";
    const status = false;
    return { success: false, message: error.message || message };
  }
}

export const deleteEvidence = async (evidenceId) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/evidence/${evidenceId}/delete/`,
      {
        method: "DELETE",
      }
    );
    if (response.ok) {
      return { success: true };
    } else {
      throw new Error("Error al eliminar la evidencia.");
    }
  } catch (error) {
    return {
      success: false,
      message: error.message || "Error del servidor al realizar la solicitud",
    };
  }
};

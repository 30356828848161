import { Box, Divider, Icon, Modal, Typography, Card, TextField } from "@mui/material";
import { useState } from "react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

export function ParticipantCreate({ open, handleClose, onSubmit }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: "600px",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
  };

  const values = {
    document: "",
    name: "",
    last_name: "",
    state: "",
    gender: "",
    location: "",
    academic_program: "",
    semester: "",
  };

  const [formValues, setFormValues] = useState(values);
  const [errors, setErrors] = useState(values);

  const handleChange = (event) => {
    const { name, value } = event.target;
    validationFields(name, value);
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const validationFields = (fieldName, value) => {
    let isValidField = true;
    let newErrors = {};
    if (value == undefined || value == null || value == "") {
      isValidField = false;
      newErrors[fieldName] = {
        message: "El campo es requerido",
        error: true,
      };
    } else if (fieldName == "document") {
      if (!/^\d+$/.test(value)) {
        isValidField = false;
        newErrors[fieldName] = {
          message: "El campo debe ser númerico",
          error: true,
        };
      }
    }
    setErrors(newErrors);
    return isValidField;
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    for (let formValue in formValues) {
      const isValidField = validationFields(formValue, formValues[formValue]);
      if (!isValidField) return;
    }
    if (
      errors &&
      (!errors.document?.error ||
        !errors.name?.error ||
        !errors.last_name?.error ||
        !errors.academic_program?.error ||
        !errors.gender?.error ||
        !errors.location?.error ||
        !errors.state?.error ||
        !errors.semester?.error)
    ) {
      onSubmit(formValues); // Llama al controlador para manejar el submit
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ overflow: "auto" }}
    >
      <Card sx={style}>
        <MDBox
          mt={-7}
          color="white"
          bgColor="info"
          variant="gradient"
          borderRadius="lg"
          p={2}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Typography id="modal-modal-title" component="h2">
            Crear Partcipante
          </Typography>
          <Icon
            sx={{
              fontSize: "2em",
              stroke: "currentColor",
              strokeWidth: "2px",
              cursor: "pointer",
              marginLeft: "auto",
            }}
            onClick={handleClose}
          >
            close
          </Icon>
        </MDBox>
        <Divider variant="middle" />
        <Box component="form" onSubmit={handleFormSubmit} sx={{ mt: 2 }}>
          <TextField
            fullWidth
            label="Cédula o Identificación"
            variant="outlined"
            name="document"
            value={formValues.document}
            onChange={handleChange}
            error={errors && errors.document?.error} // Muestra error si hay mensaje de error
            helperText={errors && errors.document?.message} // Muestra el mensaje de error
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Nombre"
            variant="outlined"
            name="name"
            value={formValues.name}
            onChange={handleChange}
            error={errors && errors.name?.error} // Muestra error si hay mensaje de error
            helperText={errors && errors.name?.message} // Muestra el mensaje de error
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Apellido"
            variant="outlined"
            name="last_name"
            value={formValues.last_name}
            onChange={handleChange}
            error={errors && errors.last_name?.error} // Muestra error si hay mensaje de error
            helperText={errors && errors.last_name?.message} // Muestra el mensaje de error
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Estado"
            variant="outlined"
            name="state"
            value={formValues.state}
            onChange={handleChange}
            error={errors && errors.state?.error} // Muestra error si hay mensaje de error
            helperText={errors && errors.state?.message} // Muestra el mensaje de error
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Género"
            variant="outlined"
            name="gender"
            value={formValues.gender}
            onChange={handleChange}
            error={errors && errors.gender?.error} // Muestra error si hay mensaje de error
            helperText={errors && errors.gender?.message} // Muestra el mensaje de error
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Ubicación Geográfica"
            variant="outlined"
            name="location"
            value={formValues.location}
            onChange={handleChange}
            error={errors && errors.location?.error} // Muestra error si hay mensaje de error
            helperText={errors && errors.location?.message} // Muestra el mensaje de error
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Programa Académico"
            variant="outlined"
            name="academic_program"
            value={formValues.academic_program}
            onChange={handleChange}
            error={errors && errors.academic_program?.error} // Muestra error si hay mensaje de error
            helperText={errors && errors.academic_program?.message} // Muestra el mensaje de error
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Semestre"
            variant="outlined"
            name="semester"
            value={formValues.semester}
            onChange={handleChange}
            error={errors && errors.semester?.error} // Muestra error si hay mensaje de error
            helperText={errors && errors.semester?.message} // Muestra el mensaje de error
            sx={{ mb: 2 }}
          />
          <MDButton type="submit" variant="gradient" color="info" fullWidth>
            Crear Participante
          </MDButton>
        </Box>
      </Card>
    </Modal>
  );
}

export const ActivitiesCreateService = async (pruebaa) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/activities/create/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(pruebaa),
    });

    const result = await response.json();
    return result;
  } catch (error) {
    const message = "Error del servidor al realizar la solicitud";
    return { status: false, message };
  }
};

export const ActivitiesListService = async (pageIndex) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/activities/?page=${pageIndex}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      throw new Error(`Error del servidor al realizar la solicitud`);
    }
  } catch (error) {
    const message = "Error del servidor al realizar la solicitud";
    return { success: false, message: error.message || message };
  }
};

export const SubcategoriesListService = async (pageIndex) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/subcategories/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      throw new Error(`Error del servidor al realizar la solicitud`);
    }
  } catch (error) {
    const message = "Error del servidor al realizar la solicitud";
    return { success: false, message: error.message || message };
  }
};

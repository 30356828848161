/* 
██╗██╗░░░██╗  ██████╗░██╗░██████╗░██╗████████╗░█████╗░██╗░░░░░
██║██║░░░██║  ██╔══██╗██║██╔════╝░██║╚══██╔══╝██╔══██╗██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░██╗░██║░░░██║░░░███████║██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░╚██╗██║░░░██║░░░██╔══██║██║░░░░░
██║╚██████╔╝  ██████╔╝██║╚██████╔╝██║░░░██║░░░██║░░██║███████╗
╚═╝░╚═════╝░  ╚═════╝░╚═╝░╚═════╝░╚═╝░░░╚═╝░░░╚═╝░░╚═╝╚══════╝
╔════════════════════════════════════════════════════════════╗
║              EQUIPO DE DESARROLLO DE SOFTWARE              ║
║                   MODULO DESARROLLADO POR                  ║
╟┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄╢
║ Juan Esteban Atehortua Sanchez                             ║
║ juan.atehortua@iudigital.edu.co                            ║
╚════════════════════════════════════════════════════════════╝
*/

// import fetchWrapper from "../../../fetchWrapper";

// export const RoleListService = async (pageIndex) => {
//   try {
//     const token = localStorage.getItem("token"); // O desde donde guardes tu token
//     const user = localStorage.getItem("user_id"); // O desde donde guardes tu user
//     const response = await fetch(`${process.env.REACT_APP_API_URL}/roles?page=${pageIndex}`, {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//         user: user,
//       },
//       body: JSON.stringify(),
//     });
//     const { payload } = await response.json();
//     return { payload };
//   } catch (error) {}
// };

// export const RolePermisionsListService = async () => {
//   try {
//     const token = localStorage.getItem("token"); // O desde donde guardes tu token
//     const user = localStorage.getItem("user_id"); // O desde donde guardes tu user
//     const response = await fetch(`${process.env.REACT_APP_API_URL}/permissions`, {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//         user: user,
//       },
//       body: JSON.stringify(),
//     });
//     const { payload } = await response.json();
//     return { payload };
//   } catch (error) {}
// };

export const RoleByIDService = async (id) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/roles/${id}/`);
    const { payload } = await response.json();
    return { payload };
  } catch (error) {}
};

export const RoleCreateService = async (payload) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/roles/roles/create/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    if (response.ok) {
      const result = await response.json();

      return result;
    } else {
      throw new Error(`Error del servidor al realizar la solicitud`);
    }
  } catch (error) {
    const message = "Error del servidor al realizar la solicitud";
    const status = false;
    return { success: false, message: error.message || message };
  }
};

export const RoleUpdateService = async (id, payload) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/roles/roles/update/${id}/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    if (response.ok) {
      const result = await response.json();

      return result;
    } else {
      throw new Error(`Error del servidor al realizar la solicitud`);
    }
  } catch (error) {
    const message = "Error del servidor al realizar la solicitud";
    const status = false;
    return { success: false, message: error.message || message };
  }
};

export const RoleDeleteService = async (id) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/roles/roles/delete/${id}/`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.ok) {
      const result = await response.json();

      return result;
    } else {
      throw new Error(`Error del servidor al realizar la solicitud`);
    }
  } catch (error) {
    const message = "Error del servidor al realizar la solicitud";
    const status = false;
    return { success: false, message: error.message || message };
  }
};

export async function RoleListService(pageIndex) {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/roles/`);
    if (response.ok) {
      const result = await response.json();

      return result;
    } else {
      throw new Error(`Error del servidor al realizar la solicitud`);
    }
  } catch (error) {
    const message = "Error del servidor al realizar la solicitud";
    const status = false;
    return { success: false, message: error.message || message };
  }
}

export async function PermissionListService(software_id) {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/permissions/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ software_id }), // Enviar software_id en el cuerpo
    });
    if (response.ok) {
      const result = await response.json();
      return { success: true, payload: result };
    } else {
      throw new Error("Error del servidor al realizar la solicitud");
    }
  } catch (error) {
    return {
      success: false,
      message: error.message || "Error del servidor al realizar la solicitud",
    };
  }
}

export async function RolePermissionsService(rol_id) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/role-permissions/${rol_id}/`
    );
    if (response.ok) {
      const result = await response.json();
      return { success: true, payload: result };
    } else {
      throw new Error("Error del servidor al realizar la solicitud");
    }
  } catch (error) {
    return {
      success: false,
      message: error.message || "Error del servidor al realizar la solicitud",
    };
  }
}

export async function updateRolePermissions(roleId, permissions) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/role-permissions/assign-permissions/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          role_id: roleId,
          permissions: permissions,
        }),
      }
    );
    if (response.ok) {
      const result = await response.json();
      return { success: true, payload: result };
    } else {
      const error = await response.json();
      throw new Error(error.message || "Error al actualizar los permisos");
    }
  } catch (error) {
    return {
      success: false,
      message: error.message || "Error del servidor al actualizar los permisos",
    };
  }
}

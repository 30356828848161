import { useEffect, useState, useRef } from "react";
import {
  UserCreateService,
  UserListService,
  UserUpdateService,
  UserDeleteService,
  UserByIDService,
} from "../services/UserService";
import UserList from "../modules/UserList";
import { UserCreate } from "../modules/UserCreate";
import { UserEdit } from "../modules/UserEdit";
import { UserDelete } from "../modules/UserDelete";
import { RoleListService } from "layouts/role/services/RoleService";
// import FilterController from "components/filter/controller/FilterController";
import Spinner from "components/MDSpinner";
import MDAlert from "components/Alert";

export default function UserController() {
  // Common Controllers
  const [isDisabled, setIsDisabled] = useState(false);
  const [defaultData, setDefaultData] = useState({});
  const [isFormModified, setIsFormModified] = useState(false);
  const [recoveredData, setRecoveredData] = useState({});
  const [editedData, setEditedData] = useState({});
  const [newData, setNewData] = useState({});
  const [buildData, setBuildData] = useState({});
  const [loading, setLoading] = useState(false);
  const header = [{}];
  const filterControllerRef = useRef();

  const dataFilter = useState([
    {
      field: "NOMBRE",
      value: "name",
    },
    {
      field: "CORREO ELECTRONICO",
      value: "email",
    },
  ]);
  const dataTable = "users"; //Tabla indicada

  // Field Check
  const [handleRoleList, setRoleList] = useState([]);

  // const requestRoleList = async () => {
  //   const result = await RoleListService();
  //   if (result && result.payload) {
  //     const { payload } = result;
  //     const rolePermitsArray = Object.entries(payload);

  //     setRoleList(rolePermitsArray);
  //   }
  // };

  // (() => {
  //   requestRoleList();
  // }, []);

  const getFieldValueNew = (fieldName) => {
    return newData[fieldName];
  };

  const getFieldValue = (fieldName) => {
    return editedData[fieldName];
  };

  const handleFieldChangeNew = (fieldName, value) => {
    if (fieldName === "data_types") {
      setNewData({
        ...newData,
        [fieldName]: [{ data_type_id: 1, value: value, principal: false }],
      });
    } else {
      setNewData({
        ...newData,

        [fieldName]: value,
      });
      handleChangeValid(fieldName, value);
    }
  };

  const handleFieldChange = (fieldName, value) => {
    if (fieldName === "data_types") {
      setEditedData({
        ...editedData,
        [fieldName]: [{ data_type_id: 1, value: value, principal: false }],
      });
    } else {
      setEditedData({
        ...editedData,

        [fieldName]: value,
      });
      handleChangeValid(fieldName, value);
    }
  };

  // ByID Controllers

  const requestUserByID = async (idUser) => {
    try {
      const user = await UserByIDService(idUser);
      console.log(user);
      const { payload } = user;
      return { payload };
    } catch (error) {}
  };

  const requestListRoles = async () => {
    try {
      // Llama al servicio para obtener la lista de roles
      const result = await RoleListService(1);

      if (result.success) {
        return result.payload;
      } else {
        handleAlert("fail", result.message);
      }
    } catch (error) {
      console.error("Error fetching roles:", error);
      // Maneja el error aquí si es necesario
    }
  };

  // const requestListRoles = async () => {
  // try {
  //   const listRoles = await RoleListService(1);
  //   console.log(listRoles)
  // const { data } = listRoles;
  //  return listRoles;
  // } catch (error) {}
  // };

  // List Controllers

  const [handleUserList, setUserList] = useState();
  const [totalPages, setTotalPages] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesStart, setEntriesStart] = useState(0);
  const [entriesEnd, setEntriesEnd] = useState(0);
  const [paginaSize, setPaginaSize] = useState(10);
  const [typeAlert, setTypeAlert] = useState("");
  const [message, setMessage] = useState("");
  const [isAlert, setIsAlert] = useState(false);

  // Maneja las alertas de exito fallo
  const handleAlert = (typeAlert, message) => {
    setTypeAlert(typeAlert);
    setMessage(message);
    setIsAlert(true);
  };
  const requestUserList = async (pageIndex) => {
    const result = await UserListService(1);

    if (result.success) {
      // const { payload } = result;
      await setUserList(result.payload);
      // await setTotalPages(payload.last_page);
      // await setTotalData(payload.total);
      // await setEntriesStart(payload.from);
      // await setEntriesEnd(payload.to);
    } else {
      handleAlert("fail", result.message);
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const pageIndexAndPageSize = async (pageSize) => {
    await setPaginaSize(pageSize);
    requestUserList(currentPage === 1 ? currentPage : (currentPage - 1) * pageSize + 1);
  };

  useEffect(() => {
    setLoading(true);
    requestUserList(currentPage);
    setLoading(false);
  }, [currentPage]);

  // List Role Controllers
  /*
  const [handleRoleList, setRoleList] = useState();

  const requestRoleList = async (pageIndex) => {
    const result = await RoleListService(pageIndex);
    if (result && result.payload) {
      const { payload } = result;
      const roleArray = Object.entries(payload.data);
      setRoleList(payload.data);
    }
  };

  (() => {
    requestRoleList();
  }, []);
 */

  // Create Controllers

  const [openCreate, setOpenCreate] = useState(false);

  const handleUserCreate = async (event) => {
    event.preventDefault();

    // Obtén la lista de roles
    const listRoles = await requestListRoles();
    setRoleList(listRoles);
    setIsDisabled(false);
    resetFieldValid();

    // Prepara los datos del nuevo usuario e incluye el software_id
    const data = {
      first_name: "",
      last_name: "",
      status: "",
      role_id: "",
      data_types: [{ data_type_id: 1, value: "", principal: false }],
    };

    setDefaultData(data);
    setNewData(data);
    setOpenCreate(true);
  };

  const submitUserCreate = async (e) => {
    e.preventDefault();

    // Obtén el software_id del localStorage
    const softwareId = localStorage.getItem("software_id");

    // Verifica si el software_id existe
    if (!softwareId) {
      console.error("El software_id no está disponible en el localStorage.");
      return;
    }

    // Incluye el software_id en los datos (newData)
    const softwareIdWithoutHyphens = softwareId.replace(/-/g, "");
    const role_id = newData["role_id"].replace(/-/g, "");
    const updatedData = {
      ...newData,
      software_id: softwareIdWithoutHyphens,
      role_id: role_id,
    };

    for (const field in isFieldValid) {
      if (!isFieldValid[field]) {
        return;
      }
    }

    const convertedObject = convertValuesToString(updatedData);

    await UserCreateService(updatedData);

    requestUserList(1);
    callHandleClickSubmit();
    closeUserCreate();
  };

  const closeUserCreate = () => setOpenCreate(false);

  // Edit Controllers

  const [openEdit, setOpenEdit] = useState(false);
  const [listRoles, setLisRoles] = useState([]);

  const handleUserEdit = async (event) => {
    event.preventDefault();

    // Obtener el ID del usuario desde el dataset del evento
    const userId = event.currentTarget.dataset.id;

    const { dataset } = event.currentTarget;

    const updatedData = {
      id: dataset.id,
      first_name: dataset.firstname,
      last_name: dataset.lastname,
      status: dataset.status,
      data_types: [{ data_type_id: 1, value: dataset.email, principal: false }],
      role_id: dataset.role,
    };

    console.log(updatedData);
    // Obtén la lista de roles
    const listRoles = await requestListRoles();
    setRoleList(listRoles);

    setRecoveredData(updatedData);
    setEditedData(updatedData);

    // Deshabilitar el formulario si el ID es "view"
    setIsDisabled(userId === "view");
    console.log(userId);
    // Abrir el modal de edición
    setOpenEdit(true);
  };

  const submitUserEdit = async (e) => {
    e.preventDefault();
    console.log("Edited Data:", editedData);

    for (const field in isFieldValid) {
      if (!isFieldValid[field]) {
        return;
      }
    }

    const convertedObject = convertValuesToString(editedData);
    await UserUpdateService(editedData.id, editedData);
    await requestUserList(1);
    callHandleClickSubmit();
    closeUserEdit();
  };

  useEffect(() => {
    console.log("newData:", newData);
    const requiredFields = ["first_name", "last_name", "status"];
    const someFieldIsEmpty = requiredFields.some((fieldName) => !newData[fieldName]);
    setIsFormModified(someFieldIsEmpty);
  }, [newData]);

  const closeUserEdit = () => setOpenEdit(false);

  // Delete Controllers

  const [openDelete, setOpenDelete] = useState(false);
  const [infoDelete, setInfoDelete] = useState({});

  const requestUserDelete = async (id) => {
    try {
      const result = await UserDeleteService(id);
      const { data } = result;
    } catch (error) {}
  };

  const handleUserDelete = (event) => {
    event.preventDefault();

    const { dataset } = event.currentTarget;
    const data = {
      id: dataset.id,
      name: dataset.name,
      lastname: dataset.lastname,
      email: dataset.email,
      status: dataset.status,
      role_id: dataset.role_id,
    };
    setInfoDelete(data);
    setOpenDelete(true);
  };

  const submitUserDelete = async (e) => {
    e.preventDefault();
    const convertedObject = convertValuesToString(infoDelete.id);
    await requestUserDelete(infoDelete.id, infoDelete.id);
    await requestUserList(1);
    callHandleClickSubmit();
    closeUserDelete();
  };

  const closeUserDelete = () => setOpenDelete(false);

  // Empty dependency array means this effect runs once on mount

  // Test Keys

  useEffect(() => {
    const hasFormChanged = Object.keys(editedData).some(
      (fieldName) => editedData[fieldName] !== recoveredData[fieldName]
    );

    const isAnyFieldEmpty = Object.values(editedData).some((value) => value === "");

    setIsFormModified(!isAnyFieldEmpty && hasFormChanged);
  }, [editedData]);

  // (() => {
  //   const requiredFields = ["name", "description"];
  //   const someFieldIsEmpty = requiredFields.some((fieldName) => !newData[fieldName]);
  //   setIsFormModified(someFieldIsEmpty);
  // }, [newData]);

  // Stringify

  function convertValuesToString(objeto) {
    const convertedObject = {};

    for (const clave in objeto) {
      if (objeto.hasOwnProperty(clave)) {
        convertedObject[clave] = String(objeto[clave]);
      }
    }

    return convertedObject;
  }

  // Estado de las validaciones
  const [isFieldValid, setIsFieldValid] = useState({
    first_name: true,
    last_name: true,
    email: true,
    role_id: true,
    status: true,
  });

  // Setea los valores de las validaciones
  const resetFieldValid = () => {
    setIsFieldValid({
      first_name: true,
      last_name: true,
      email: true,
      role_id: true,
      status: true,
    });
  };

  // Expresiones regulares para diferentes tipos de campos
  const validationPatterns = {
    first_name: /^[a-zA-Z\s]*$/, // Solo permite letras y espacios
    last_name: /^[a-zA-Z\s]*$/, // Solo permite letras y espacios
    email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, // Verifica el formato de correo electrónico
    role_id: /^[\s\S]*\S[\s\S]*$/, // Al menos un carácter que no sea un espacio
    status: /^[\s\S]*\S[\s\S]*$/, // Al menos un carácter que no sea un espacio
  };

  // Compara el valor del input con la expresion regular requerida
  const validateField = (value, pattern) => {
    return pattern.test(value);
  };

  // Comprueba la validez del campo y luego establece la validez en el estado
  const handleChangeValid = (fieldName, value) => {
    const isFieldValid = validateField(value, validationPatterns[fieldName]);

    setIsFieldValid((prevValidity) => ({
      ...prevValidity,
      [fieldName]: isFieldValid,
    }));
  };

  // Función para llamar a handleClickSubmit desde el componente padre
  const callHandleClickSubmit = () => {
    if (filterControllerRef.current) {
      filterControllerRef.current.handleClickSubmit();
    }
  };

  return (
    <>
      <Spinner loading={loading}></Spinner>
      <MDAlert
        isAlert={isAlert}
        setIsAlert={setIsAlert}
        typeAlert={typeAlert}
        message={message}
      ></MDAlert>
      {/* <FilterController
        dataTable={dataTable}
        data={handleUserList}
        dataFilter={dataFilter}
        setListData={setUserList}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setTotalPages={setTotalPages}
        requestData={requestUserList}
        // setError={setError}
        setTotalData={setTotalData}
        setEntriesEnd={setEntriesEnd}
        setEntriesStart={setEntriesStart}
        setLoading={setLoading}
        ref={filterControllerRef}
      ></FilterController> */}
      <UserList
        handleUserList={handleUserList}
        handleUserCreate={handleUserCreate}
        handleUserEdit={handleUserEdit}
        handleUserDelete={handleUserDelete}
        pageIndexAndPageSize={pageIndexAndPageSize}
        entriesStart={entriesStart}
        entriesEnd={entriesEnd}
        totalData={totalData}
        totalPages={totalPages}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
      />

      <UserCreate
        open={openCreate}
        handleUserCreate={handleUserCreate}
        handleClose={closeUserCreate}
        isFormModified={isFormModified}
        isDisabled={isDisabled}
        getFieldValue={getFieldValueNew}
        handleFieldChange={handleFieldChangeNew}
        handleSubmit={submitUserCreate}
        isFieldValid={isFieldValid}
        handleRoleList={handleRoleList}
      />

      <UserEdit
        open={openEdit}
        handleClose={closeUserEdit}
        isFormModified={isFormModified}
        isDisabled={isDisabled}
        getFieldValue={getFieldValue}
        handleFieldChange={handleFieldChange}
        handleSubmit={submitUserEdit}
        listRoles={listRoles}
        isFieldValid={isFieldValid}
        handleRoleList={handleRoleList}
      />

      <UserDelete
        openDelete={openDelete}
        closeDelete={closeUserDelete}
        infoDelete={infoDelete}
        submitDelete={submitUserDelete}
      />
    </>
  );
}

import { useState, useEffect } from "react";
import {
  ActivitiesCreateService,
  ActivitiesListService,
  SubcategoriesListService, // Asegúrate de que esta función esté importada
} from "../services/ActivitiesServices";
import { ActivitiesCreate } from "../modules/ActivitiesCreate";
import ActivitiesList from "../modules/ActivitiesList";
import Spinner from "components/MDSpinner";
import MDAlert from "components/Alert";
import { bool } from "prop-types";

export default function ActivitiesController() {
  const [isDisabled, setIsDisabled] = useState(false);
  const [newData, setNewData] = useState({
    // title: "",
    // fecha: "",
    // description: "",
    // keywords: "",
    // evidence_formats: "",
    // is_certifiable: "",
    // categories: "", // Asegúrate de que el nombre del campo coincida con el del formulario
  });
  const [openCreate, setOpenCreate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ visible: false, message: "", type: "" });
  const [handleActivitiesList, setActivitiesList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginaSize, setPaginaSize] = useState(10);
  const [subCategoriesList, setSubCategoriesList] = useState([]); // Array para almacenar las subcategorías

  // Función para obtener la lista de actividades
  const requestActivitiesList = async (pageIndex) => {
    setLoading(true);
    try {
      const result = await ActivitiesListService(pageIndex);
      if (result.success) {
        setActivitiesList(result.payload);
        setTotalPages(result.totalPages || 0);
        setTotalData(result.totalData || 0);
      } else {
        handleAlert("error", result.message);
      }
    } catch (error) {
      handleAlert("error", "Error al obtener las actividades");
    } finally {
      setLoading(false);
    }
  };

  // Función para obtener las subcategorías
  const requestSubCategoriesList = async (pageIndex) => {
    setLoading(true);
    try {
      const result = await SubcategoriesListService(pageIndex);
      if (result.success) {
        setSubCategoriesList(result.payload);
      } else {
        handleAlert("error", result.message);
      }
    } catch (error) {
      handleAlert("error", "Error al obtener las actividades");
    } finally {
      setLoading(false);
    }
  };
  /* const fetchSubCategoriesList = async () => {
    setLoading(true);
    try {
      const result = await SubcategoriesListService();
      if (result.success) {
        setSubCategoriesList(result.payload);
      } else {
        handleAlert("error", result.message);
      }
    } catch (error) {
      handleAlert("error", "Error al obtener las subcategorías");
    } finally {
      setLoading(false);
    }
  }; */

  // Manejar el cambio de página
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Manejar el cambio de tamaño de página
  const pageIndexAndPageSize = async (pageSize) => {
    setPaginaSize(pageSize);
    requestActivitiesList((currentPage - 1) * pageSize + 1);
  };

  // Abrir el formulario de creación
  const handleActivityCreate = (event) => {
    event.preventDefault();
    setNewData({
      title: "",
      fecha: "",
      description: "",
      keywords: "",
      evidence_formats: "",
      is_certifiable: "",
      categories: "", // Asegúrate de que el nombre del campo coincida con el del formulario
    });
    setOpenCreate(true);
  };

  console.log("crearF");

  // Enviar la nueva actividad
  const submitActivityCreate = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const result = await ActivitiesCreateService(newData);
      if (result.success) {
        setAlert({ visible: true, message: "Actividad creada con éxito", type: "success" });
        requestActivitiesList((currentPage - 1) * paginaSize + 1);
      } else {
        setAlert({ visible: true, message: result.message, type: "error" });
      }
    } catch (error) {
      setAlert({ visible: true, message: "Error al crear la actividad", type: "error" });
    } finally {
      setLoading(false);
      setOpenCreate(false);
    }
  };

  // Cerrar el formulario de creación
  const closeActivityCreate = () => setOpenCreate(false);

  // Manejar el cambio de campo en el formulario
  const handleFieldChange = (name, value) => {
    if (name === "subcategories") {
      setNewData((prevData) => ({
        ...prevData,
        [name]: [value],
      }));
    } else {
      setNewData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  // Manejar alertas
  const handleAlert = (typeAlert, message) => {
    setAlert({ visible: true, message, type: typeAlert });
  };

  // Llamar a las funciones cuando el componente se monta o las dependencias cambian
  useEffect(() => {
    requestActivitiesList();
    requestSubCategoriesList(); // Obtener subcategorías al montar el componente
  }, []);
  console.log("Subcategorías:", subCategoriesList);

  return (
    <>
      {loading && <Spinner />}
      {alert.visible && <MDAlert type={alert.type} message={alert.message} />}
      <ActivitiesCreate
        open={openCreate}
        handleClose={closeActivityCreate}
        isDisabled={isDisabled}
        getFieldValue={(field) => newData[field] || ""}
        handleFieldChange={handleFieldChange}
        handleSubmit={submitActivityCreate}
        fetchSubCategories={subCategoriesList} // Pasar las subcategorías como prop
      />
      <ActivitiesList
        handleActivitiesList={handleActivitiesList}
        pageIndexAndPageSize={pageIndexAndPageSize}
        totalData={totalData}
        totalPages={totalPages}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        handleActivitiesCreate={handleActivityCreate}
      />
    </>
  );
}

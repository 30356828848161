export const ParticipantCreateService = async (payload) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/participants/create/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    if (response.ok) {
      const result = await response.json();

      return { success: true, result: result };
    } else {
      throw new Error(`Error del servidor al realizar la solicitud`);
    }
  } catch (error) {
    const message = "Error del servidor al realizar la solicitud";
    return { success: false, message: error.message || message };
  }
};

export async function ParticipantListService(id, pageIndex) {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/participants/listar/`);
    if (response.ok) {
      const result = await response.json();

      return result;
    } else {
      throw new Error(`Error del servidor al realizar la solicitud`);
    }
  } catch (error) {
    const message = "Error del servidor al realizar la solicitud";
    return { success: false, message: error.message || message };
  }
}

//consultar el endpoint que me retornara si se creo correctamente la fuente de datos o error si ocurre y retornar esos datos
export const participantUploadService = async (data) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/participants/upload/`, {
      method: "POST", // Usa 'PUT' para actualizar un recurso existente.
      //headers: {
      //"Content-Type": "application/json",
      //},
      body: data, // Convierte el objeto 'data' a una cadena JSON y envíalo en el cuerpo de la solicitud.
    });
    if (response.ok) {
      const result = await response.json();

      return result;
    } else {
      throw new Error(`Error del servidor al realizar la solicitud`);
    }
  } catch (error) {
    const message = "Error del servidor al realizar la solicitud";
    const status = false;
  }
};
export const ParticipantUpdateService = async (id, payload) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/participants/update/${id}/`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );

    if (response.ok) {
      const result = await response.json();
      return { success: true, result: result };
    } else {
      const errorText = await response.text();
      throw new Error(`Error del servidor al realizar la solicitud: ${errorText}`);
    }
  } catch (error) {
    const message = "Error del servidor al realizar la solicitud";
    return { success: false, message: error.message || message };
  }
};

import { Box, Divider, Icon, Modal, Typography, Card, TextField, Button } from "@mui/material";
import { useState } from "react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

export function EvidenceCreate({ open, handleClose, onSubmit }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: "600px",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
  };

  const [formValues, setFormValues] = useState({
    description: "",
    file: null,
  });

  const [errors, setErrors] = useState({
    description: false,
    file: false,
  });

  const handleChange = (event) => {
    const { name, value, files } = event.target;
    setFormValues({
      ...formValues,
      [name]: files ? files[0] : value, // Si hay archivos, guarda el archivo, si no, guarda el valor del campo
    });
  };

  const validateForm = () => {
    const newErrors = {
      description: !formValues.description, // Error si está vacío
      file: !formValues.file, // Error si no se ha subido un archivo
    };

    setErrors(newErrors);

    // Retornar true si no hay errores
    return !newErrors.description && !newErrors.file;
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    // Validar el formulario
    if (validateForm()) {
      onSubmit(formValues); // Si la validación pasa, llama a onSubmit
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card sx={style}>
        <MDBox
          mt={-7}
          color="white"
          bgColor="info"
          variant="gradient"
          borderRadius="lg"
          p={2}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Typography id="modal-modal-title" component="h2">
            Crear Evidencia
          </Typography>
          <Icon
            sx={{
              fontSize: "2em",
              stroke: "currentColor",
              strokeWidth: "2px",
              cursor: "pointer",
              marginLeft: "auto",
            }}
            onClick={handleClose}
          >
            close
          </Icon>
        </MDBox>
        <Divider variant="middle" />
        <Box component="form" onSubmit={handleFormSubmit} sx={{ mt: 2 }}>
          <TextField
            fullWidth
            label="Descripción"
            variant="outlined"
            name="description"
            value={formValues.description}
            onChange={handleChange}
            sx={{ mb: 2 }}
            error={errors.description}
            helperText={errors.description && "La descripción es obligatoria"}
          />
          <Button variant="gradient" component="label" sx={{ mb: 2 }}>
            Subir Archivo
            <input type="file" name="file" hidden onChange={handleChange} />
          </Button>
          {errors.file && (
            <Typography color="error" variant="caption" sx={{ mb: 2 }}>
              Es necesario subir un archivo.
            </Typography>
          )}
          <MDButton type="submit" variant="gradient" color="info" fullWidth>
            Crear Evidencia
          </MDButton>
        </Box>
      </Card>
    </Modal>
  );
}

import React from "react";
import { Pagination, Stack } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";

export default function ActivitiesList({
  handleActivitiesList,
  handleActivitiesCreate,
  handleActivitiesDelete,
  handleActivitiesUpdate,
  handleNavigateEvidence,
  pageIndexAndPageSize,
  totalData,
  totalPages,
  currentPage,
  handlePageChange,
}) {
  const navigate = useNavigate(); // Obtén la función navigate de useNavigate

  // Define la función para manejar la navegación
  const handleNavigate = (id) => {
    // Construye la URL dinámica usando el ID pasado como parámetro
    navigate(`/evidence/${id}`);
  };

  const handleNavigateParticpants = (id) => {
    // Construye la URL dinámica usando el ID pasado como parámetro
    navigate(`/participants/${id}`);
  };

  return (
    <>
      <MDBox style={{ display: "flex", justifyContent: "flex-end", marginRight: "2vw" }}>
        <MDButton onClick={handleActivitiesCreate} variant="gradient" color="info">
          Crear
        </MDButton>
      </MDBox>
      <DataTable
        table={{
          columns: [
            { Header: "Fecha", accessor: "fecha" },
            { Header: "Título", accessor: "title" },
            { Header: "Subcategorías", accessor: "subcategories" },
            { Header: "Evidencias", accessor: "evidence_formats" },
            { Header: "Participantes", accessor: "participants" },
            { Header: "Descripción", accessor: "description" },
            { Header: "Certificable", accessor: "is_certifiable" },
            { Header: "acciones", accessor: "action", width: "12%", align: "center" },
          ],
          rows: handleActivitiesList
            ? handleActivitiesList.map((userMan) => {
                // Transformar subcategorías a una cadena de nombres
                const subcategoriesNames = userMan.subcategories
                  .map((subcat) => subcat.subcategory.name) // Ajusta esto según la estructura real
                  .join(", ");

                return {
                  id: userMan.id,
                  fecha: userMan.fecha,
                  title: userMan.title,
                  subcategories: subcategoriesNames,
                  evidence_formats: (
                    <button
                      onClick={() => handleNavigate(userMan.id)}
                      style={{
                        border: "none",
                        background: "none",
                        color: "blue",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      Ver evidencia
                    </button>
                  ),
                  participants: (
                    <button
                      onClick={() => handleNavigateParticpants(userMan.id)}
                      style={{
                        border: "none",
                        background: "none",
                        color: "blue",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      Ver Particpantes
                    </button>
                  ),
                  description: userMan.description,
                  is_certifiable: `${userMan.is_certifiable}`,
                  action: (
                    <>
                      <a
                        data-id={userMan.id}
                        data-Fecha={userMan.fecha}
                        data-Título={userMan.title}
                        data-Subcategorías={subcategoriesNames}
                        data-Evidencias={userMan.evidence_formats}
                        data-Descripción={userMan.description}
                        data-Certificable={userMan.is_certifiable}
                        onClick={handleActivitiesUpdate}
                        style={{ marginLeft: "8px", cursor: "pointer" }}
                      >
                        <EditOutlinedIcon
                          titleAccess="Editar"
                          style={{
                            fontSize: "200px",
                            width: "24px",
                            height: "24px",
                            backgroundImage: "#000000",
                          }}
                        />
                      </a>
                      <a
                        data-id={userMan.id}
                        data-name={userMan.name}
                        onClick={handleActivitiesDelete}
                        style={{ marginLeft: "8px", cursor: "pointer" }}
                      >
                        <DeleteForeverOutlinedIcon
                          titleAccess="Eliminar"
                          style={{
                            fontSize: "200px",
                            width: "24px",
                            height: "24px",
                            backgroundImage: "#000000",
                          }}
                        />
                      </a>
                    </>
                  ),
                };
              })
            : [],
        }}
        canSearch={false}
        isSorted={false}
        entriesPerPage={false}
        showTotalEntries={false}
      />
      <MDBox style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </MDBox>
    </>
  );
}

import { useState, useEffect } from "react";

const usePermission = (requiredPermission, requiredModule) => {
  const [hasPermission, setHasPermission] = useState(false);

  useEffect(() => {
    // Leer los permisos directamente desde el localStorage
    const storedPermissions = JSON.parse(localStorage.getItem("permissions")) || [];
    // Verificar si el usuario tiene el permiso requerido
    setHasPermission(
      storedPermissions.some(
        (permission) =>
          permission.name === requiredPermission && permission.module === requiredModule
      )
    );
  }, [requiredPermission, requiredModule]);

  return hasPermission;
};

export default usePermission;
